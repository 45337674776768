import { makeAutoObservable } from 'mobx';
import { HostedConsentModel } from '../types/hosted-consent.types.ts';
import { FileMetadata } from '../types/common.types.ts';

class ConsentStore {
    consent: HostedConsentModel<any, any> | null = null;
    evidenceDocument: string | null = null;
    evidenceDocumentMetadata: FileMetadata | null = null;
    termsChecked: boolean = false;
    constructor() {
        makeAutoObservable(this);
    }

    setConsent<CtxData extends Record<string, any>, CountryConfig extends Record<string, any>>(
        consent: HostedConsentModel<CtxData, CountryConfig>,
    ): void {
        this.consent = consent;
    }

    setEvidenceDocument(document: string | null): void {
        this.evidenceDocument = document;
    }

    setEvidenceDocumentMetadata(metadata: FileMetadata | null): void {
        this.evidenceDocumentMetadata = metadata;
    }

    setTermsChecked(checked: boolean): void {
        this.termsChecked = checked;
    }
}

const singleton = new ConsentStore();

export default singleton;
