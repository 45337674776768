import { ReactElement, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ZodSchema } from 'zod';
import { PostcoderSearchResult } from '../../../types/common.types';
import { Countries, CountryCodes } from '../../../types/countries.types';
import Flex, { Align, Gap } from '../../atoms/Flex/Flex';
import Muted from '../../atoms/Muted/Muted';
import TextInput from '../../atoms/TextInput/TextInput';
import css from './AddressForm.module.scss';

interface AddressFormProps {
    onChange: (newState: PostcoderSearchResult) => void;
    onValidationError?: (error: string | undefined) => void;
    initialAddress?: PostcoderSearchResult;
    validationSchema?: ZodSchema;
    label?: string;
    fixedCountry?: CountryCodes;
}

function AddressForm({
    onChange,
    onValidationError = () => {},
    initialAddress,
    fixedCountry,
    label,
    validationSchema,
}: AddressFormProps): ReactElement {
    const [address, setAddress] = useState<PostcoderSearchResult>({
        organisation: '',
        summaryline: '',
        number: '',
        street: '',
        dependentlocality: '',
        posttown: '',
        county: '',
        postcode: '',
        country: fixedCountry ? Countries[fixedCountry].name : '',
    });
    const { t } = useTranslation();

    function buildSummaryLine(address: PostcoderSearchResult): string {
        return `${address.organisation ? `${address.organisation}, ` : ''}${address.number} ${address.street}, ${address.postcode} ${address.posttown}`.trim();
    }
    function handleChange(event: React.ChangeEvent<HTMLInputElement>): void {
        const { name, value } = event.target;
        const newAddress = { ...address, [name]: value };

        if (!Object.keys(address).includes(name)) return;

        setAddress({
            ...newAddress,
            summaryline: buildSummaryLine(newAddress),
        });
        onChange({
            ...newAddress,
            summaryline: buildSummaryLine(newAddress),
        });

        if (validationSchema) {
            const { error } = validationSchema.safeParse(newAddress);
            onValidationError(error?.issues[0]?.message);
        }
    }
    function ignoreCommas(e: React.KeyboardEvent<HTMLInputElement>): void {
        if (e.key === ',') {
            e.preventDefault();
        }
    }

    return (
        <div className={css.manualAddressInput}>
            <Muted>{label ?? t('common:address')}:</Muted>
            <TextInput
                onKeyDown={ignoreCommas}
                onChange={handleChange}
                value={address.street}
                name="street"
                placeholder={t('common:street')}></TextInput>
            <Flex align={Align.START} gap={Gap.SM}>
                <TextInput
                    onChange={handleChange}
                    onKeyDown={ignoreCommas}
                    value={address.number}
                    name="number"
                    placeholder={t('common:number')}></TextInput>
                <TextInput
                    onChange={handleChange}
                    onKeyDown={ignoreCommas}
                    value={address.postcode}
                    name="postcode"
                    placeholder={t('common:postcode')}></TextInput>
            </Flex>
            <Flex align={Align.START} gap={Gap.SM}>
                <TextInput
                    onChange={handleChange}
                    onKeyDown={ignoreCommas}
                    value={address.posttown}
                    name="posttown"
                    placeholder={t('common:city')}></TextInput>
                <TextInput
                    onChange={handleChange}
                    onKeyDown={ignoreCommas}
                    value={address.county}
                    name="county"
                    placeholder={t('common:county')}></TextInput>
            </Flex>
            <Flex align={Align.START} gap={Gap.SM}>
                <TextInput
                    onChange={handleChange}
                    onKeyDown={ignoreCommas}
                    name="country"
                    disabled={Boolean(fixedCountry)}
                    tabIndex={fixedCountry ? -1 : undefined}
                    value={
                        fixedCountry ? Countries[fixedCountry].name : initialAddress?.country ?? ''
                    }
                    placeholder={t('common:country')}></TextInput>
            </Flex>
            <TextInput
                onChange={handleChange}
                onKeyDown={ignoreCommas}
                value={address.organisation ?? ''}
                name="organisation"
                placeholder={t('common:buildingName')}></TextInput>
        </div>
    );
}

export default AddressForm;
